import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  Form,
  Input,
  Radio,
  Space,
  Button,
  Checkbox,
  DatePicker,
  Modal,
  Typography,
  Upload,
  notification,
} from 'antd';

import moment from 'moment';
import {
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
  CheckOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
  HourglassOutlined,
  CloseOutlined,
  CloseCircleTwoTone,
} from '@ant-design/icons';

import { API_URL } from '../env';
import type {
  NotificationAdhocRequest,
  NotificationAdhocResponse,
} from '../../../api/controllers/alerts/adhocNotifications';
import { getAuthHeaders } from 'relay-env';

async function postNotification(body: NotificationAdhocRequest) {
  let formData = new FormData();
  // @ts-expect-error
  const file = document.querySelector('#filepicker')?.files[0];

  formData.append('json', JSON.stringify(body));

  formData.append('image', file);

  const res = await fetch(`${API_URL}/notify`, {
    headers: {
      ...(await getAuthHeaders()),
    },
    method: 'POST',
    credentials: 'include',
    body: formData,
  });

  const data: NotificationAdhocResponse = await res.json();

  if (data.error != null) {
    throw new Error(data.error);
  }

  return data;
}

export default function SendNotification() {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <div style={{ maxWidth: 800, margin: '0 auto' }}>
      <Typography.Title level={2}>Send a notification</Typography.Title>
      <Form
        name="basic"
        form={form}
        initialValues={{
          title: undefined,
          message: undefined,
          url: undefined,
          image_url: undefined,
          audience: 'editors',
          send_immediately: true,
          send_after: undefined,
        }}
        style={{
          display: 'grid',
          gridGap: 8,
        }}
      >
        <Form.Item label="Title" name="title" required={false}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Message"
          name="message"
          required={true}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Link"
          help="Examples: https://www.nicekicks.com/jordan-pro-strong-bred-dc8418-006/ https://www.nike.com/t/blazer-low-77-vintage-mens-shoe-kw53J0/DJ6201-100"
          name="url"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input required={true} />
        </Form.Item>

        <Form.Item
          label="Image"
          help="If you're linking to a nicekicks.com post, we'll use the image post automatically"
          name="file"
        >
          <input type="file" accept="image/*" id="filepicker" />
        </Form.Item>

        <Form.Item
          label="Who should get this notification?"
          name="audience"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value="everybody">Everybody</Radio>
            <Radio value="developers">Developers</Radio>
            <Radio value="editors">Nice Kicks team</Radio>
            <Radio value="similar" disabled>
              People who liked similar content (TODO)
            </Radio>
            <Radio value="user" disabled>
              A specific user (TODO)
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="When should this notification start sending?"
          name="send_immediately"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>Immediately</Radio>
            <Radio value={false}>After a specific time</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item dependencies={['send_immediately']} noStyle>
          {({ getFieldValue }) =>
            getFieldValue('send_immediately') === false && (
              <Form.Item name="send_after" required={true}>
                <DatePicker
                  format={'dddd MMMM Do YYYY, h:mm a'}
                  showTime={{ format: 'h:mm a' }}
                  style={{ width: '100%' }}
                  defaultValue={undefined}
                  allowClear={true}
                />
              </Form.Item>
            )
          }
        </Form.Item>
      </Form>

      <Button
        size="large"
        block={true}
        key="submit"
        type="primary"
        loading={isSubmitting}
        onClick={async () => {
          let values;
          try {
            values = await form.validateFields();
          } catch (err) {
            return;
          }

          try {
            setIsSubmitting(true);
            const { recipients_count } = await postNotification({
              title: values.title,
              message: values.message,
              url: values.url,
              audience: values.audience,
              send_after: values.send_after,
            });
            notification.success({
              message: `Notifications sent to ${recipients_count} recipients`,
            });
          } catch (err: any) {
            console.error(err);
            notification.error({
              message: err.message,
            });
          } finally {
            setIsSubmitting(false);
          }
        }}
      >
        Submit
      </Button>
    </div>
  );
}
