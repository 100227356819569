// import {
//   Network,
//   FetchFunction,
//   RecordSource,
//   Store,
//   Environment,
// } from 'relay-runtime';
import Cookies from 'js-cookie';

export const API_URL = window.location.href.includes('localhost')
  ? 'http://localhost:8003'
  : '';

export async function getAuthHeaders() {
  const jwt = Cookies.get('jwt');

  let authHeader = {};
  if (jwt != null) {
    authHeader = { Authorization: `Bearer ${jwt}` };
  }
  return authHeader;
}

// const fetchQuery: FetchFunction = async (operation, variables) => {
//   const authHeaders = await getAuthHeaders();
//   const res = await fetch(`${API_URL}/graphql`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       ...authHeaders,
//     },
//     body: JSON.stringify({
//       query: operation.text,
//       variables,
//     }),
//   });
//   const data = await res.json();
//   return data;
// };

// const network = Network.create(fetchQuery);
// const recordSource = new RecordSource();
// const store = new Store(recordSource);

// const NKRelayEnvironment = new Environment({ network, store });

// export default NKRelayEnvironment;
