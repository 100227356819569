import React, { useState, Suspense } from 'react';
import 'antd/dist/antd.css';
import './App.css';
import Cookies from 'js-cookie';

import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
} from 'react-router-dom';
import SendNotification from './components/SendNotification';
// import Dashboard from './components/Dashboard';

import { API_URL } from 'env';
// import KickCheckDashboard from 'components/KickCheck/Dashboard';

function App() {
  if (Cookies.get('jwt') == null) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <a href={`${API_URL}/auth/web/google/start`}>Login with Google</a>
      </div>
    );
  }

  return (
    <div style={{ height: '100vh' }}>
      <Router>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <a
            href={`${API_URL}/auth/web/logout`}
            style={{ color: 'red', padding: 16 }}
          >
            Logout
          </a>
        </div>
        <SendNotification />
      </Router>
    </div>
  );
}

export default App;
