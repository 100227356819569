const FUNCTIONS_URL_PROD =
  'https://us-central1-nicekicksapp.cloudfunctions.net/f';

const FUNCTIONS_URL_STAGING =
  'https://us-central1-nicekicksapp.cloudfunctions.net/staging';

const FUNCTIONS_URL_DEV = 'http://localhost:8080';

const isDev = window.location.href.includes('localhost');

const isStaging = !!localStorage['STAGING'];

console.log({ isStaging });

export const FUNCTIONS_URL = isStaging
  ? FUNCTIONS_URL_STAGING
  : isDev
  ? FUNCTIONS_URL_DEV
  : FUNCTIONS_URL_PROD;

export const API_URL = isStaging ? '/staging' : '/api';
